import { Component, createRef } from 'react';

// project imports
import Datatable from 'shared/datatable';
import { duplicateProfileList,updateStatus,secret_key } from 'shared/settings/apiconfig';
import { getBuyerUrl, getBuyerHeaders,getSellerUrl, getSellerHeaders,} from 'shared/http';
import { connect } from 'react-redux';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import DatePicker from "react-datepicker";
import {Modal, Spinner,Form, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';

/**
 * this page is used to list alert duplicate address
 * 
 * @param props
 * 
 * @returns html code to render the alert duplicate address list
 * 
**/

export class alertDuplicateAddress extends Component {

    config;
    initialSearchState;
    filterSearch;
    id;
    constructor(props) {
        super(props);
        this.dataTable = createRef();
        this.id = '';
        /* initial search state */
        this.initialSearchState = {
            email: { field: 'email', value: '', op: 'LIKE', join: 'AND' },
            starttime: { field: 'dupCreatedDate', value: '', op: '>=', join: 'AND' },
            endtime: { field: 'dupCreatedDate', value: '', op: '<=', join: 'AND' },
            type: { field: 'type', value: '', op: 'LIKE', join: 'AND' },
            registbuyerid: { field: 'registbuyerid', value: '', op: 'LIKE', join: 'AND' },
            registdetails: { field: 'registdetails', value: '', op: 'LIKE', join: 'AND' },
            similarbuyerids: { field: 'similarbuyerids', value: '', op: 'LIKE', join: 'AND' },
            takeaction: { field: 'takeaction', value: '', op: 'LIKE', join: 'AND' },
            status: { field: 'deletestatus', value: '',op: '=', join: 'AND'},
            archive: { field: 'archive', value: 0, op: '=', join: 'AND' },
        }
        this.state = {
            search: this.initialSearchState,
            searchData: false,
            tableData: [],
            buyerStatus : { value: '' },
            buyerToken: '',
            filterSearch: {
                archive: { field: 'archive', value: 0, op: '=', join: 'AND' },
            },
        }

        this.config = {
            name: 'duplicateAddress',
            url: duplicateProfileList,
            params: {},
            columns: [
              
                { name: 'Type', property: 'type', sort: true, width: '150px' },
                { name: 'Buyer First Name', property: 'firstname', sort: true, width: '160px'},
                { name: 'Buyer Last Name', property: 'lastname', sort: true, width: '160px'},
                { name: 'Last Modified', property: 'modifieddate', sort: true, width: '160px', columnType: 'custom', customColumn: this.customDate },
                { name: 'Registration Date', property: 'dupCreatedDate', sort: true, width: '160px', columnType: 'custom', customColumn: this.customCreatedDate },
                { name: 'Buyer Email', property: 'registbuyerid', sort: true, width: '130px' },
                { name: 'Buyer ID', property: 'id', sort: true, width: '130px' },
                { name: 'Buyer Address', property: 'registdetails', width: '180px' },
                { name: 'Similar Buyer Ids', property: 'similarbuyerids', width: '150px', columnType: 'custom', customColumn: this.customCount },
                { name: 'Status', property: 'takeaction',  sortType: 'num', width: '150px', columnType: 'custom', customColumn: this.customDrowpDown },
                { name: 'Suspended', property: 'suspensionStatus', sortType: 'num', width: '150px', columnType: 'custom', customColumn: this.customSuspended },
            ],
            sort: [{ field: 'dupCreatedDate', direction: 'desc' }],
            pagination: true,
            field: ["id","type","registbuyerid","registdetails","similarbuyerids","takeaction","modifieddate","firstname","lastname","createdDate","archive","dupCreatedDate","suspensionStatus"],
            buyerUrl: {},
        };

        if (props.location.state && props.location.state.datatable && props.location.state.datatable.search) {
           let tempSearch = { ...this.initialSearchState };
            const search = [...props.location.state.datatable.search];
            search.forEach(element => {
                let temp = { ...element };
                if (typeof temp['value'] === 'string') {
                    temp['value'] = temp['value'].replace(/(^\*)|(\*$)/g, '');
                }
                if (temp['field'] === "dupCreatedDate" && temp['op'] === '>=') {
                    temp['value'] = new Date(temp['value']);
                    temp['field'] = 'starttime';
                }
                if (temp['field'] === "dupCreatedDate" && temp['op'] === '<=') {
                    temp['value'] = new Date(temp['value']);
                    temp['field'] = 'endtime';
                }
              
                tempSearch[temp['field']] = temp;
            });
            this.state['search'] = tempSearch;
            this.state['searchData'] = true;
           
        }

        this.dataTable = createRef(); 
       
    }


    componentDidMount() { 
        if (!this.props?.location?.state?.datatable ) {
            this.enableSearch()
        } 
        this.getSecretKey();
    }
    getSecretKey= async () =>{
        await  axios.get(getSellerUrl(`${secret_key}`), getSellerHeaders()).then(response => {
            if (response.data.status.toLowerCase() === 'success') {
                this.setState({
                    buyerToken: response.data.accessToken,

                });
            }
        }).catch(error => {
        });
    }

    customCount = (row, state) => {
        let data = {}
        data['datatable'] = this.state.tableData.stateData;
        return (
                <div className="d-flex flex-row justify-content-center align-items-center border bg-white clickable"
                    style={{ borderRadius: "50%", height: "30px", width: "30px" }}
                    onClick={() => this.routeToDuplicateAddressDetails(JSON.parse(row['similarbuyerids']), state)}>
                    {
                        row['similarbuyerids'] ?
                            <b style={{ marginTop: "1px" }}>{JSON.parse(row['similarbuyerids']).length}</b> :
                            <span style={{ marginTop: "1px" }}>0</span>
                    }
                </div>
        );
    };
    customSuspended = (value) => {
        if (value.suspensionStatus == 'ALL' || value.suspensionStatus === 'PARTIAL') {
            return 'Yes'
        }
        else {
            return 'No'
        }
    }
    customDrowpDown = (value) => {
        return(
            <Form.Control as="select" name="status"  onChange={(event) => { this.buyerDrowdownChange(event, value) }}>
                <option value='new' disabled={value.takeaction == 'Inprogress'} >New</option>
                <option value='Inprogress'>In Progress</option>
                <option value="Completed">Completed</option>
            </Form.Control> 
        )
    }
    buyerDrowdownChange = (event, value) => {
        const data = {
            'id': value.id,
            "takeaction": event.target.value
        };
        if (event.target.value === 'Completed') {
            data['archive'] = 1
        }
        axios.put(getBuyerUrl(updateStatus), ({ "data": data }), getBuyerHeaders(this.state.buyerToken))
            .then(response => {
                this.dataTable.current.getTableData(null, true);
            })
            .catch(error => {

            })

        this.setState({
            ...this.state,
            takeaction: {
                ...this.state.takeaction,
                value: event.target.value
            }
        })
    }

    customDate = (value) => {
        if (value['modifieddate']) {
            return `${moment.utc(value['modifieddate']).local().format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    };
    customCreatedDate = (value) => {
        if (value['dupCreatedDate']) {
            return `${moment.utc(value['dupCreatedDate']).local().format('MM/DD/YYYY hh:mm A')} ${momentTz.tz(momentTz.tz.guess()).zoneAbbr()}`;
        }
        return '- - -';
    };

    routeToDuplicateAddressDetails = (buyerData, state) => {
        this.props.history.push({ pathname: `/alerts/duplicateaddress/detail`, state: {buyerids: buyerData, tabledata: this.state.tableData.stateData} });
    };

    enableSearch = () => {
        let search = {};
        search = {
            type: { ...this.state.search.type, value: this.state.search.type.value ? this.state.search.type.value + '*' : '' },
            registbuyerid: { ...this.state.search.registbuyerid, value: this.state.search.registbuyerid.value ?  this.state.search.registbuyerid.value + '*' : '' },
            registdetails: { ...this.state.search.registdetails, value: this.state.search.registdetails.value ? '*' + this.state.search.registdetails.value + '*' : '' },
            similarbuyerids: { ...this.state.search.similarbuyerids, value: this.state.search.similarbuyerids.value ? '*' + this.state.search.similarbuyerids.value + '*' : '' },
            takeaction: { ...this.state.search.takeaction, value: this.state.search.takeaction.value ? '*' + this.state.search.takeaction.value + '*' : '' },
            starttime: { ...this.state.search.starttime, value: this.state.search.starttime.value ? moment(new Date(this.state.search.starttime.value)).startOf('day').format("YYYY-MM-DD HH:mm:ss") : '' },
            endtime: { ...this.state.search.endtime, value: this.state.search.endtime.value ? moment(new Date(this.state.search.endtime.value)).endOf('day').format("YYYY-MM-DD HH:mm:ss") : '' },
            email: { ...this.state.search.email, value: this.state.search.email.value ? '*' + this.state.search.email.value + '*' : '' },
            status: {...this.state.search.status, value: this.state.search.status.value ? this.state.search.status.value : ''},
            archive: { field: 'archive', value: 0, op: '=', join: 'AND' },
        }
        
        if(this.props?.location?.state){
            this.setState({
                searchData: true
            })
        }
        this.setState({
            filterSearch: search,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
    }

    updateTableData = (event) => {
        this.setState({
            ...this.state,
            tableData: event
        });
    }

    resetState = () => {
        this.setState({
            ...this.state,
            search: this.initialSearchState,
            filterSearch: this.initialSearchState,
        }, () => {
            this.dataTable.current.searchData();
        });
    }

    updateTableData = (event) => {
        this.setState({
            ...this.state,
            tableData: event
        });
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.enableSearch()
        }
    }
    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    searchInputChange = (event) => {
        let eventValue = event.target.value;
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [event.target.name]: {
                    ...this.state.search[event.target.name],
                    value: eventValue
                }
            }
        }, () => {
           
        });
    }
    setDate = (control, date) => {
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [control]: {
                    ...this.state.search[control],
                    value: date
                },
            }
        });

    }

    render() {

        return (
            <div className="buyer-list">
                 <div className="sale-search">
                    <div className="sales-list-search">
                        <Form className="sales-list-font">
                            <div className="sales-search-container">

                                <Row>
                                        <Col>
                                        <label>Bidder email</label>
                                        <Form.Control onKeyDown={this._handleKeyDown} type="text" name="email" value={this.state.search.email.value} onChange={(event) => this.searchInputChange(event)} />
                                    </Col>
                                    <Col>
                                        <label>Registration Date From</label>
                                        <div>
                                            <label style={{ width: "100%" }} onClick={e => this.start.state.open && e.preventDefault()}>
                                                <DatePicker ref={r => this.start = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.starttime.value} selected={this.state.search.starttime.value} onChange={date => { this.setDate('starttime', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                                <i style={{ position: "absolute", top: "52%", right: "12%", color: "#959393" }} className="fa fa-calendar" aria-hidden="true"></i>
                                            </label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <label>Registration Date To</label>
                                        <div>
                                            <label style={{ width: "100%" }} onClick={e => this.end.state.open && e.preventDefault()}>
                                                <DatePicker  minDate={this.state.search.starttime.value ? this.state.search.starttime.value : ''} ref={r => this.end = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.endtime.value} selected={this.state.search.endtime.value} onChange={date => { this.setDate('endtime', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                                <i style={{ position: "absolute", top: "52%", right: "12%", color: "#959393" }} className="fa fa-calendar" aria-hidden="true"></i>
                                            </label>
                                        </div>
                                    </Col>
                                    <Col className={"px-1"}>
                                        
                                         <label>Status</label>
                                         <Form.Control as="select" name="takeaction" value={this.state.search.takeaction.value} onChange={(event) => { this.searchInputChange(event) }}>
                                            <option value='' selected>All</option>
                                            <option value='new'>New</option>
                                            <option value="Inprogress">In Progress</option>
                                        </Form.Control> 
                                    </Col> 
                                    <Col>
                                        <div className="mt-3">
                                            <Button className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                            <Button variant="search-button"  onClick={() => this.enableSearch()}>Search</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>

                    </div>
                </div>
                <div className="buyer-table">
                    <Datatable {...this.props} config={{ ...this.config }} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable}
                     onDataUpdate={this.updateTableData} />
                </div>
            </div>
        );
    }
}
export default connect(state => state)(alertDuplicateAddress);
